@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
	font-family: 'Pretendard-Regular';
	src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
:root {
	--background: #ffffff;
	--foreground: #171717;
}

@media (prefers-color-scheme: dark) {
	:root {
		--background: #0a0a0a;
		--foreground: #ededed;
	}
}

body {
	color: var(--foreground);
	background: var(--background);
	font-family: 'Pretendard-Regular', sans-serif;
}


.no-scroll::-webkit-scrollbar {
	display: none;
}

.chat_list{
	-webkit-mask-image: linear-gradient(transparent, #fff 30px);
	mask-image: linear-gradient(transparent, #fff 30px);
	will-change: bottom;
	overscroll-behavior:none;
}


.slide {
	animation: move 6s infinite linear;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
@keyframes move {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(-110%);
	}
}

.snow {
	position: absolute;
	width: 5px;
	height: 5px;
	background: white;
	border-radius: 50%;
	box-shadow: 0 0 3px 3px white;
}


.player__gradient {
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.40) 3.53%, rgba(0, 0, 0, 0.00) 19.22%, rgba(0, 0, 0, 0.00) 50.6%, rgba(0, 0, 0, 0.60) 81.99%);
}